import {  useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchTenantsDetail,
  tenantsOpenModal,
} from "../../redux/Tenants/TenantsAction";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import form from "antd/es/form";

interface NameLinkProps<T> {
  record: any;
  name: string;
  navigateTo: string;
  isopenModal?: boolean;
}

function NameLink<T>(props: NameLinkProps<T>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const Namelink = () => {
    if (props.isopenModal) {
      dispatch(tenantsOpenModal());
      dispatch(fetchTenantsDetail(props.record.id));
      
      
    } else {
      navigate(props.navigateTo, { state: props.record });
    }
  };

  return (
    <div className="link" onClick={Namelink}>
      {props.name}
    </div>
  );
}

export default NameLink;
