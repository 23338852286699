import dayjs from "dayjs";
import dayJs from "dayjs";

export const convertDateToDayJS = (date: string) => {
  const dateParsed = dayJs(date, "DD MMMM YYYY @ HH:mm:ss");
  return dateParsed;
};


export const convertDayJSToFullDate = (date: dayjs.Dayjs) => {
  const formattedDate = date.format("DD MMMM YYYY @ HH:mm:ss");
  return formattedDate;
};
