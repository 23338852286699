/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import ToggleBtn from "../SidebarComponent/ToggleBtn/ToggleBtn";
import "./Nav.css";
import { useNavigate } from "react-router";
import ToggleBtn from "../SidebarComponent/ToggleBtn/ToggleBtn";
// import { logoutUser } from "../../services/auth/AuthAPI";

function AuthNav(props: any) {
  const location = window.location.pathname;
  const user = JSON.parse(
    JSON.parse(JSON.stringify(localStorage.getItem("userData")))
  ).idToken.payload;
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg  position-sticky top-0">
      <div className="container-fluid d-flex align-items-center px-4 py-2">
        <ToggleBtn />
        <a className="navbar-brand " href="/live">
          <img src={require("../../../assets/img/LCProdIcon.png")} alt="" />
        </a>

        <div
          className="collapse navbar-collapse justify-content-between  "
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav gap-3 ">
            <div className="nav-item">
              <button
                onClick={() => navigate("/image-verification")}
                className={`nav-link  ${
                  location.includes("image-verification") ? "active" : ""
                }  `}
              >
                <div className="d-flex align-items-center gap-1">
                  {/* <span className="material-symbols-outlined">person</span> */}
                  <span>IMAGE VERIFICATION</span>
                  {/* <span className="material-symbols-outlined">arrow_drop_down</span> */}
                </div>
              </button>
            </div>
            <div className="nav-item">
              <button
                onClick={() => navigate("/live")}
                className={`nav-link  ${
                  location.includes("live") ? "active" : ""
                }  `}
              >
                <div className="d-flex align-items-center gap-1">
                  {/* <span className="material-symbols-outlined">person</span> */}
                  <span>LIVE PLATFORM</span>
                  {/* <span className="material-symbols-outlined">arrow_drop_down</span> */}
                </div>
              </button>
            </div>

            <div className="nav-item dropdown">
              <button
                className={`nav-link custom-dropdown-toggle ${
                  location.includes("settings") ? "active" : ""
                }`}
                id="settingsDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center gap-1">
                  <span>SETTINGS</span>
                  <span className="material-symbols-outlined">
                    arrow_drop_down
                  </span>
                </div>
              </button>
              <ul className="dropdown-menu" aria-labelledby="settingsDropdown">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/settings");
                      const dropdown =
                        document.getElementById("settingsDropdown");
                      if (dropdown) {
                        dropdown.click(); // Close dropdown if it exists
                      }
                    }}
                  >
                    Tenants
                  </button>
                </li>
              </ul>
            </div>

            <div className="nav-item">
              <a className="nav-link" target="_blank" href="/qa">
                <div className="d-flex align-items-center gap-1">
                  {/* <span className="material-symbols-outlined">person</span> */}
                  <span>Q & A</span>
                  {/* <span className="material-symbols-outlined">arrow_drop_down</span> */}
                </div>
              </a>
            </div>
          </div>

          <div className="dropdown">
            <button
              className="user-container  "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="d-flex align-items-center gap-1">
                <span className="material-symbols-outlined">person</span>
                <span>{user.name}</span>
                <span className="material-symbols-outlined">
                  arrow_drop_down
                </span>
              </div>
            </button>

            <div
              className="dropdown-menu drop-menu-container "
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className="dropdown-item dropdown-item-customize"
                onClick={() => console.log("logout")}
                href="/"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AuthNav;
