import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { notification } from "antd";
import {
  openNotification,
  openStaticErrorNotification,
  openErrorNotification,
} from "../../components/Template/NotificationComponent";
import {
  ImageType,
  DefaultImageVerificationRequestBodyType,
} from "../../constant/logoProductionType";
import { get, post } from "../../services/API/ApiClient";
import { fetchDropdownData } from "../LogoProduction/LogoProductionAction";
import { RootState } from "../Store";

///////////////////Start image verification
export const fetchBrandsImageVerification = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "logoProduction/fetchBrandsImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const previousBatchId = getState().imageVerificationState.currentBatchId;
      const reqBody = {
        ...getState().imageVerificationState.currentRequestBody,
      };
      const responseBatchPickList = await get(
        "/pick-list/batch",
        "logoCloudImageVerification"
      );
      const batchPickList = responseBatchPickList.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      if (batchPickList.find((item: any) => item.value === previousBatchId)) {
        const response = await post(
          "/search",
          "logoCloudImageVerification",
          reqBody
        );
        dispatch(setBatchPickList(batchPickList));
        const currentBatchId = response.data.data[0]
          ? response.data.data[0].batchId
          : getState().imageVerificationState.currentBatchId;
        dispatch(setCurrentBatchId(currentBatchId));
        return response.data;
      } else {
        const newReqBody = { ...reqBody, batchId: 0 };
        dispatch(updateRequestBodyForImageVerification(newReqBody));
        const response = await post(
          "/search",
          "logoCloudImageVerification",
          newReqBody
        );
        dispatch(setBatchPickList(batchPickList));
        const currentBatchId = response.data.data[0]
          ? response.data.data[0].batchId
          : getState().imageVerificationState.currentBatchId;
        dispatch(setCurrentBatchId(currentBatchId));
        return response.data;
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchPaginateBrandsImageVerification = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "logoProduction/fetchPaginateBrandsImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const reqBody = {
        ...getState().imageVerificationState.currentRequestBody,
        pageNum: getState().imageVerificationState.pagination.page,
      };
      const response = await post(
        "/search",
        "logoCloudImageVerification",
        reqBody
      );

      return {
        data: response.data.data,
        hasMore: response.data.data.length > 0,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const searchImageVerificationBrands = createAsyncThunk<
  any,
  { searchKeyword: string },
  { state: RootState }
>(
  "logoProduction/searchImageVerificationBrands",
  async (args, { getState, dispatch }) => {
    dispatch(resetImageVerificationTable());
    dispatch(
      updateRequestBodyForImageVerification({
        ...getState().imageVerificationState.currentRequestBody,
        keyword: args.searchKeyword,
      })
    );

    dispatch(fetchBrandsImageVerification({}));
  }
);
export const fetchAutoRefreshImageVerification = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "logoProduction/fetchAutoRefreshImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    const currentDataLength =
      getState().imageVerificationState.data.length === 0
        ? 30
        : getState().imageVerificationState.data.length;

    try {
      const reqBody = {
        ...getState().imageVerificationState.currentRequestBody,
        pageSize: Math.ceil(currentDataLength / 30) * 30,
      };
      //For batch picklist
      const responseBatchPickList = await get(
        "/pick-list/batch",
        "logoCloudImageVerification"
      );
      const batchPickList = responseBatchPickList.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      dispatch(setBatchPickList(batchPickList));

      const response = await post(
        "/search",
        "logoCloudImageVerification",
        reqBody
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const imageVerificationAutoRefresh = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "logoProduction/imageVerificationAutoRefresh",
  async (args, { getState, dispatch, rejectWithValue }) => {
    if (getState().imageVerificationState.autoRefresh.currentIntervalId) {
      clearInterval(
        getState().imageVerificationState.autoRefresh.currentIntervalId
      );
    }
    if (getState().imageVerificationState.autoRefresh.autoRefresh) {
      const intervalId = setInterval(() => {
        dispatch(fetchAutoRefreshImageVerification({}));
      }, 5000);
      dispatch(setAutoRefreshForImageVerification({ intervalId }));
    }
  }
);

//Detail page for image verification
export const fetchBrandDetailImageVerification = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  "logoProduction/fetchBrandDetailImageVerification",
  async ({ batchId, brandId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await get(
        `/batches/${batchId}/brands/${brandId}`,
        "logoCloudImageVerification"
      );
      const dropdownData = await fetchDropdownData();
      return { brandDetail: response, dropdownData: dropdownData };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const saveBrandDetailImageVerification = createAsyncThunk<
  any,
  { brandDetail: any; brandId: number; batchId: number },
  { state: RootState }
>(
  "logoProduction/saveBrandDetailImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      notification.destroy();
      const response = await post(
        `/batches/${args.batchId}/brands`,
        "logoCloudImageVerification",
        { ...args.brandDetail, id: args.brandId, batchId: args.batchId }
      );
      openNotification(
        "Brand Details Saved",
        `Brand details for '${args.brandDetail.name}' has been saved successfully,`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openStaticErrorNotification(
        "Failed to save brand details.",
        e.response.data.detailedMessages,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);

//upload Image in image verification
// export const uploadImage = createAsyncThunk<any, { image: any}, { state: RootState }>("logoProduction/fetchIndustriesListImageVerification", async (args, { getState, dispatch, rejectWithValue }) => {
//   try {
//     console.log(args.image,"image in uploadImage action")
//     notification.destroy();
//     // const payload = { sectors: args.sectorDetail };
//     const response = await post(`/batches/1/brands/317/images/upload`, "logoCloud",{ ...args.image});
//     console.log(response,"upload image response");
//     // const formattedArray=formatIndustryList(response);
//     // dispatch(setIndustryList(formattedArray));
//     openNotification("Brand Details Saved", `Brand details for '${response.data}' has been saved successfully,`, "top");
//     return response.data;
//   } catch (e: any) {
//     openStaticErrorNotification("Failed to save brand details.", e.response.data.detailedMessages, "top");
//     return rejectWithValue(e);
//   }
// });
export const uploadImageInImageVerification = createAsyncThunk<
  any,
  { brandName: string; image: any; batchId: string; brandId: string },
  { state: RootState }
>(
  "logoProduction/uploadImageInImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      notification.destroy();

      const formData = new FormData();
      formData.append("imageFile", args.image);
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/images/upload`,
        "logoCloudImageVerification",
        formData
      );

      openNotification(
        "Image Upload Successful",
        `Image uploaded successfully for brand '${args.brandName}'.`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openStaticErrorNotification(
        "Failed to upload image.",
        e.response.data.detailedMessages,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);

//Save industry brand details
export const saveBrandIndustryDetailImageVerification = createAsyncThunk<
  any,
  { industryDetail: any; brandId: number; batchId: number; brandName: string },
  { state: RootState }
>(
  "logoProduction/saveBrandIndustryDetailImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      notification.destroy();
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/industries`,
        "logoCloudImageVerification",
        { ...args.industryDetail, brandId: args.brandId, batchId: args.batchId }
      );
      openNotification(
        "Industry Details Saved",
        `Industry details for '${args.brandName}' has been saved successfully,`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openStaticErrorNotification(
        "Failed to save industry details.",
        e.response.data.detailedMessages,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);

export const saveImageDetailImageVerification = createAsyncThunk<
  any,
  { imageDetail: ImageType; imageId: number; brandId: number; batchId: number },
  { state: RootState }
>(
  "logoProduction/saveImageDetailImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/images/${args.imageId}`,
        "logoCloudImageVerification",
        {
          ...args.imageDetail,
          id: args.imageId,
          brandId: args.brandId,
          batchId: args.batchId,
        }
      );
      openNotification(
        "Image Details Saved",
        `Image ID -${args.imageId}, details has been saved successfully.`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openErrorNotification(
        "Failed to save image details.",
        e.response.data.detailedMessages,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);
export const approveImageForImageVerification = createAsyncThunk<
  any,
  { imageId: number; imageDetail: ImageType; brandId: number; batchId: number },
  { state: RootState }
>(
  "logoProduction/approveImageForImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/images/${args.imageId}/approve`,
        "logoCloudImageVerification",
        {
          ...args.imageDetail,
          id: args.imageId,
          brandId: args.brandId,
          batchId: args.batchId,
        }
      );
      openNotification(
        "Image Approved",
        `Image (ID: ${args.imageId}) has been approved successfully`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openErrorNotification(
        "Failed to approve image.",
        `Image (ID: ${args.imageId}) has not approved.Please try again later.`,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);
export const rejectImageForImageVerification = createAsyncThunk<
  any,
  {
    id: number;
    brandId: number;
    batchId: number;
    rejectReason: string;
    remarks: string;
  },
  { state: RootState }
>(
  "logoProduction/rejectImageForImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/images/${args.id}/reject`,
        "logoCloudImageVerification",
        { ...args }
      );
      openNotification(
        "Image Rejected",
        `Image (ID: ${args.id}) has been rejected successfully`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openErrorNotification(
        "Failed to reject image.",
        `Image (ID: ${args.id}) has not rejected.Please try again later.`,
        "top"
      );
      return rejectWithValue(e);
    }
  }
);
export const goLiveForImageVerification = createAsyncThunk<
  any,
  { brandId: number; batchId: number; brandLocation: any },
  { state: RootState }
>(
  "logoProduction/goLiveForImageVerification",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const data = getState().imageVerificationState.brandDetailState.data;
      const response = await post(
        `/batches/${args.batchId}/brands/${args.brandId}/go-live`,
        "logoCloudImageVerification",
        {
          ...getState().imageVerificationState.brandDetailState.data,
          brandId: args.brandId,
          batchId: args.batchId,
          brandLocation: args.brandLocation,
        }
      );

      openNotification(
        "Go Live",
        `Brand has been gone live successfully`,
        "top"
      );
      return response.data;
    } catch (e: any) {
      openStaticErrorNotification(
        "Failed to go live.",
        e.response.data.detailedMessages[0]
          ? e.response.data.detailedMessages[0]
          : "There is something wrong during go live.",
        "top"
      );
      // openStaticErrorNotification("Failed to go live.", "There is something wrong during go live.", "top");
      throw e;
    }
  }
);

export const downloadImage = createAsyncThunk<
  any,
  { imageId: number; batchId: number; brandId: number },
  { state: RootState }
>(
  "logoProduction/downloadImage",
  async (args, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await get(
        `/batches/${args.batchId}/brands/${args.brandId}/images/${args.imageId}`,
        "logoCloudImageVerification"
      );
      const imageUrl = response.data.downloadUrl;

      // Fetch the image data
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();

      // Extract the image format from the response (e.g., "png")
      const imageFormat = response.data.imageFormat;

      // Use the saveAs function to download the image
      saveAs(imageBlob, `${args.imageId}.${imageFormat}`);
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error downloading image");
    }
  }
);

export const updateStockSymbolForImageVerification = createAction<{
  symbol: string;
  exchangeName: string;
  id: number;
  index: number;
}>("updateStockSymbolSlice/updateStockSymbolForImageVerification");
export const addSymbolForImageVerification = createAction<{
  symbol: string;
  exchangeName: string;
}>("logoProduction/addSymbolForImageVerification");
export const editSymbolForImageVerification = createAction<{
  symbol: string;
  exchangeName: string;
}>("logoProduction/editSymbolForImageVerification");
export const deleteSymbolForImageVerification = createAction<{
  symbol: string;
  exchangeName: string;
}>("logoProduction/deleteSymbolForImageVerification");
export const setStockSymbolsForImageVerification = createAction<any>(
  "logoProduction/setStockSymbolsForImageVerification"
);
export const setIsEditedForImageVerification = createAction<boolean>(
  "logoProduction/setIsUpdatedForImageVerification"
);
export const setCurrentImageIndexImageVerification = createAction<number>(
  "logoProduction/setCurrentImageIndexImageVerification"
);
export const cleanBrandDetailImageVerification = createAction(
  "logoProduction/cleanBrandDetailImageVerification"
);
//End of detail page for image verification

//Start the generic action for image verification
export const setBatchPickList = createAction<any>(
  "logoProduction/setBatchPickList"
);
export const setCurrentBatchId = createAction<number>(
  "logoProduction/setCurrentBatchId"
);
export const resetImageVerificationTable = createAction(
  "logoProduction/resetImageVerificationTable"
);
export const updateRequestBodyForImageVerification =
  createAction<DefaultImageVerificationRequestBodyType>(
    "logoProduction/updateRequestBodyForImageVerification"
  );
export const setAutoRefreshForImageVerification = createAction<{
  intervalId: NodeJS.Timer;
}>("logoProduction/setAutoRefreshForImageVerification");
export const switchAutoFreshForImageVerification = createAction<{
  autoRefresh: boolean;
}>("logoProduction/switchAutoFreshForImageVerification");
export const stopAutoRefreshForImageVerification = createAction(
  "logoProduction/stopAutoRefreshForImageVerification"
);
//End of generic action for image verification

//Image verification for modal state
export const openUnsavedChangesModalForImageVerification = createAction(
  "logoProduction/openUnsavedChangesModalForImageVerification"
);
export const closeUnsavedChangesModalForImageVerification = createAction(
  "logoProduction/closeUnsavedChangesModalForImageVerification"
);
export const openAddStockSymbolModalForImageVerification = createAction(
  "logoProduction/openAddStockSymbolModalForImageVerification"
);
export const closeAddStockSymbolModalForImageVerification = createAction(
  "logoProduction/closeAddStockSymbolModalForImageVerification"
);
export const openEditStockSymbolModalForImageVerification = createAction(
  "logoProduction/openEditStockSymbolModalForImageVerification"
);
export const closeEditStockSymbolModalForImageVerification = createAction(
  "logoProduction/closeEditStockSymbolModalForImageVerification"
);
export const openDeleteStockSymbolModalForImageVerification = createAction(
  "logoProduction/openDeleteStockSymbolModalForImageVerification"
);
export const closeDeleteStockSymbolModalForImageVerification = createAction(
  "logoProduction/closeDeleteStockSymbolModalForImageVerification"
);
export const openGoLiveConfirmModal = createAction(
  "logoProduction/openGoLiveConfirmModal"
);
export const closeGoLiveConfirmModal = createAction(
  "logoProduction/closeGoLiveConfirmModal"
);

/////////////End of image verification
