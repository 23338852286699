import React from 'react'

import {ColumnsType} from "antd/es/table"
import IsDataNull from "../Template/IsDataNull";
import NameLink from "../Template/NameLink";


export interface TenantsTableColumnProperties {
    key: string;
    name: string;
    description: string;
    onboardDate: string;
    offboardDate: string;
    systemName: string;
    isLongSessionAllowed: boolean;
    maxBrandsAddIn: number;
    maxBrandsApi: number;
    isActive: boolean;
  }


const TenantsTableColumn: ColumnsType<TenantsTableColumnProperties> =[


  {
    title: "Name",
    key: "displayName",
    dataIndex: "displayName",
    sorter: true,
    render: (data, record) => (
      <NameLink<TenantsTableColumnProperties>
        isopenModal={true}
        name={data}
        record={record}
        navigateTo=""
      />
    ),
    width: 150,
  },
  {
    title: "Onboard Date" ,
    key: "onboardDate",
    dataIndex: "onboardDate",
    sorter: true,
    render: (data) => <IsDataNull data={data} />,
    width: 100,
  },
  {
    title: "Offboard Date",
    key: "offboardDate",
    dataIndex: "offboardDate",
    sorter: true,
    render: (data) => <IsDataNull data={data} />,
    width: 100,
  },
  {
    title: "Max Search Items Allowed/Day via Add In",
    key: "maxItemsToSearchViaAddin",
    dataIndex: "maxItemsToSearchViaAddin",
    sorter: true,
    render: (data) => <IsDataNull data={data} />,
    width: 200,
  },
  {
    title: "Max Search Items Allowed/Day via API",
    key: "maxItemsToSearchViaApi",
    dataIndex: "maxItemsToSearchViaApi",
    sorter: true,
    render: (data) => <IsDataNull data={data} />,
    width: 200,
  },
 
  {
    title: "Is Active",
    key: "isActive",
    dataIndex: "isActive",
    sorter: true,
    render: (data) => (data ? "True" : "False"),
    width: 100,
  },


];

export default TenantsTableColumn
