import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent/TableComponent";
import TenantsTableColumn from "../../components/TenantsComponent/TenantsTableColumn";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import Search from "antd/es/input/Search";
import AutoRefreshTenantsCheckbox from "../../components/TenantsComponent/AutoRefreshTenantsCheckBox";
import {
  fetchPaginateTenants,
  fetchTenants,
  resetTenantsTable,
  searchTenants,
  stopTenantsAutoRefresh,
  updateTenantsRequestBody,

  //Modal
  tenantsOpenModal,
  tenantsCloseModal,
} from "../../redux/Tenants/TenantsAction";
import TenantsModal from "../../components/TenantsComponent/TenantsModal";
import { Spin } from "antd";

export interface TenantsPageProps {}

function TenantsPage(props: TenantsPageProps) {
  const tenantsPageState = useSelector(
    (state: RootState) => state.TenantsState
  );

  const eachTenantState = useSelector(
    (state: RootState) => state.TenantsState.tenantState
  );

  const [searchValue, setSearchValue] = useState(
    tenantsPageState.currentRequestBody.keyword
  );
  const dispatch = useAppDispatch();

  //For Sorting
  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = {
      ...tenantsPageState.currentRequestBody,
      sortColumn: sorter.field,
      isAsc: isAscend(sorter.order),
    };
    dispatch(resetTenantsTable());
    dispatch(updateTenantsRequestBody(newSortingRequestBody));
    dispatch(fetchTenants({}));
  };
  const onSearchTenants = (value: string) => {
    dispatch(
      searchTenants({
        searchKeyword: value,
      })
    );
  };

  //Each rendering
  useEffect(() => {
    dispatch(fetchTenants({}));
    return () => {
      dispatch(stopTenantsAutoRefresh());
      dispatch(resetTenantsTable());
    };
  }, []);

  return (
    <div className="body-container gap-3">
      <h2>Tenants</h2>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between">
          <Search
            type="text"
            size="large"
            value={searchValue}
            onSearch={(value) => onSearchTenants(value)}
            allowClear
            placeholder="Search by Name"
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 580 }}
          />
        </div>
        <div className="d-flex justify-content-between h-100 ">
          <AutoRefreshTenantsCheckbox />
        </div>
      </div>

      <TableComponent
        columns={TenantsTableColumn}
        dataSource={tenantsPageState.data}
        pagination={tenantsPageState.pagination}
        handleTableSorting={handleTableSorting}
        fetchPaginatedData={() => fetchPaginateTenants({})}
        loading={tenantsPageState.loading}
        tableKey={"Tenants"}
      />

     
        <TenantsModal
          open={() => dispatch(tenantsOpenModal())}
          close={() => dispatch(tenantsCloseModal())}
          openState={eachTenantState.tenantsModalState}
        />
    
    </div>
  );
}

export default TenantsPage;
