import React, { useEffect, useRef, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import { Label } from "@fluentui/react";
import { error, log } from "console";
// import { loginUser } from "../../services/auth/AuthAPI";
import { Spin } from "antd";
import { FormikHelpers, useFormik } from "formik";
import { Hub, Auth } from "aws-amplify";
import { FederatedLogoProductionProvider } from "../../constant/constant";

function LoginPage(props: any) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
  }, []);

  const federatedLogin = async () => {
    // localStorage.setItem("idpLogin", true);
    setLoading(true);

    await Auth.federatedSignIn({ provider: FederatedLogoProductionProvider });
  };

  return (
    <div className="login-main-container container-fluid">
      <div className="login-container">
        <h3 className="login-title">Login</h3>
        <Spin spinning={loading}>
          <div className="login-wrapper border-shadow-container">
            <div className="mw-50">
              <p>Please use your organization account to access LogoCloud Production.</p>
              <p>To change or reset your password of your organization account, please contact your system administrator or help desk.</p>
            </div>
            <button
              className="button "
              onClick={() => {
                setLoading(true);
                federatedLogin();
              }}>
              Login via Organization
            </button>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default LoginPage;
