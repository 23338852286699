import { Button, Checkbox, Form, Modal, Select } from "antd";
import Input from "antd/es/input/Input";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { useNavigate } from "react-router";
import { createBrand } from "../../../redux/LivePlatform/LivePlatformAction";
import { validateDomain, validateNameLength } from "../BrandQualityCheckTab/BrandQualityCheckHelpers";
import { useMemo } from "react";

type CreateBrandModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
};

function CreateBrandModal(props: CreateBrandModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();


    const CreateBrandModalContent = useMemo(() => {
    const onFinish = (values: any) => {
      form.resetFields();
      dispatch(
        createBrand({
          brandDetail: { ...values,Id: 0 }
        })
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          navigate(`detail/${response.payload.id}`);
        } else if (response.meta.requestStatus === "rejected") {
          navigate("/live");
        }
      })
      props.close();
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100">
        <h4>Create Brand</h4>
      
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              name="createBrandForm"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              initialValues={{ isDummyDomain: false }}
              colon={false}
            >
              {/* Name Field */}
              <Form.Item
                className="form-item"
                label="Name"
                name="Name"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide name.",
                  },
                  {
                    validator: validateNameLength,
                  },
                ]}
              >
                <Input  />
              </Form.Item>

              {/* Website Field */}
              <Form.Item
                className="form-item"
                label="Website"
                name="Domain"
                labelAlign="left"
                required={!form.getFieldValue("isDummyDomain")}
                 validateTrigger="onBlur"
                rules={[
                  // {
                  //   required: !form.getFieldValue("isDummyDomain"),
                  //   message: "Please provide website.",
                  // },
                  {
                    validator: (rule, value, callback) =>
                      validateDomain(rule, value,form.getFieldValue("isDummyDomain"), callback),
                  },
                  
                ]}
              >
                <Input
                  disabled={form.getFieldValue("isDummyDomain")}       
                />
              </Form.Item>

              {/* Dummy Domain Checkbox */}
              <Form.Item
                name="isDummyDomain"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 15 }}
                style={{ marginTop: "-20px" }}
                
              >
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    form.setFieldValue("isDummyDomain", isChecked);
                    if (isChecked) {
                      form.setFieldsValue({ Domain: "" });
                    }
                  }}
                >
                  Is dummy website?
                </Checkbox>
              </Form.Item>

              <div className="d-flex gap-3 justify-content-end">
                <Button className="saveButton" type="primary" htmlType="submit">
                  Create
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
       
      </div>
    );
  // };
}, [form, props]);
  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}
    >
      {CreateBrandModalContent}
    </Modal>
  );
}

export default CreateBrandModal;
