import { ColumnsType } from "antd/es/table";

// import { devToolsEnhancer } from "@reduxjs/toolkit/dist/devtoolsExtension";
import IsDataNull from "../Template/IsDataNull";
import BrandDetailNavigationLink from "./BrandDetailNavigationLink";
import ShortenedName from "../Template/ShortenedName";
import Header from "../Template/Header";

export interface LivePlatformTableColumnsProperties {
  id: number;
  createdBy: string;
  createdAt: string;
  shortMessage: string;
}

const LivePlatformTableColumns: ColumnsType<LivePlatformTableColumnsProperties> = [
  {
    title: <Header data="Name" />,
    dataIndex: "name",

    // defaultSortOrder: "ascend",
    sorter: true,
    // width: 300,
    render: (data, record) => (
      <BrandDetailNavigationLink
        data={data}
        record={record}
      />
    ),
  },
  {
    title: <Header data={"Website"} />,
    dataIndex: "domain",
    sorter: true,
    // width: 130,
    render: (data) => (
      <ShortenedName
        data={data}
        maxLength={30}
        toolTipText={data}
      />
    ),
  },

  {
    title: <Header data={"Image #"} />,
    dataIndex: "imagesCount",
    key: "shortMessage",
    sorter: true,
    width: 90,
    render: (data) => <IsDataNull data={data} />,
  },

  {
    title: <Header data={"Country"} />,
    dataIndex: "country",

    sorter: true,
    width: 120,
    render: (data) => (
      <ShortenedName
        data={data}
        maxLength={15}
        toolTipText={data}
      />
    ),
  },
  {
    title: <Header data={"Stock Symbols"} />,
    dataIndex: "stockSymbols",
    key: "stockSymbols",
    // sorter: true,
    width: 250,

    render: (data) => {
      const stringData = data.join(", ");
      return (
        <ShortenedName
          data={stringData}
          maxLength={35}
          toolTipText={stringData}
        />
      );
    },
  },
  {
    title: <Header data={"Tags"} />,
    dataIndex: "tags",

    // sorter: true,
    // width: 10,
    width: 250,

    render: (data) => {
      const stringData = data.join(", ");
      return (
        <ShortenedName
          data={stringData}
          maxLength={35}
          toolTipText={stringData}
        />
      );
    },
  },
  {
    title: <Header data={"ISIN"} />,
    dataIndex: "isin",

    sorter: true,
    width: 120,
    render: (data) => (
      <ShortenedName
        data={data}
        maxLength={15}
        toolTipText={data}
      />
    ),
  },
  {
    title: <Header data={"Status"} />,
    dataIndex: "status",
    defaultSortOrder: "ascend",
    // sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data.replace('Awaiting Qc', 'Awaiting QC')} />,
  },
];

export default LivePlatformTableColumns;
