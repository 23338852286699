import dayjs from "dayjs";
export const addStockSymbolValidator = (rule: any, value: any, callback: any, stockSymbols: Array<any>, ExchangeName: any) => {
  if (value && !value.trim()) {
    callback("Symbol cannot be empty.");
  } else {
    if (stockSymbols.some((stockSymbol) => stockSymbol.symbol === value && stockSymbol.exchangeName === ExchangeName)) {
      callback("Symbol Name already exists");
    } else {
      callback();
    }
  }
};
