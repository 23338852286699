import { Auth } from "aws-amplify";
// import { MESSAGES } from "../../Utils/ErrorMessages";

async function loginUser(email: string, password: string) {
  try {
    localStorage.clear();
    await Auth.signIn(email, password);
    localStorage.setItem("isAuthenticated", "true");
    let userData = await getSessionData();
    localStorage.setItem("userData", JSON.stringify(userData));
  } catch (e: any) {
    throw e;
  }
}
function logoutUser() {
  localStorage.clear();
}

async function registerUser(name: string, email: string, password: string) {
  try {
    await Auth.signUp({
      username: name,
      attributes: {
        email: email,
        name: name,
      },
      password: password,
    });

    // props.history.push("/confirmRegister");
  } catch (e: any) {
    // props.setisLoading(false);
    if (e.code === "InvalidPasswordException") {
      // props.Seterrormsg({ authErr: MESSAGES.PASSWORD_RESTRICTION });
    } else if (e.code === "UsernameExistsException") {
      // props.Seterrormsg({
      // 	authErr:
      // 		"An error is encountered while registering your account. Please contact service desk.",
      // });
    } else {
      // props.Seterrormsg({ authErr: e.message });
    }
  }
}

async function confirmUser(props: any) {
  try {
    await Auth.confirmSignUp(props.user.username, props.user.confirmationCode, {
      forceAliasCreation: true,
    });
    props.history.push("/dashboard");
  } catch (e: any) {
    if (e.code === "InvalidParameterException") {
      // props.Seterrormsg({ authErr: MESSAGES.CODE_RESTRICTION });
    } else {
      // props.Seterrormsg({ authErr: e.message });
    }
  }
}

async function forgotPassword(email: string) {
  try {
    await Auth.forgotPassword(email);
  } catch (e: any) {
    throw e;
  }
}
async function forgotPasswordSubmit(email: string, code: string, password: string) {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);
  } catch (e: any) {
    throw e;
  }
}
async function getSessionData() {
  const user = await Auth.currentSession();
  return user;
}

const isUserLoggedIn = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    let userData = await getSessionData();
    localStorage.setItem("userData", JSON.stringify(userData));
    return true;
  } catch (error) {
    return false;
  }
};

export { loginUser, logoutUser, registerUser, confirmUser, getSessionData, isUserLoggedIn, forgotPassword, forgotPasswordSubmit };
